import React from "react";
import { Link } from "gatsby";
import styles from './Navbar.module.scss';

const NavBar = () => {
  return (
    <nav className={styles['navbar']}>
      {/* <ul className={styles['navbar__ul']}> */}
        {/* <li className={styles['navbar__li']}> */}
      <a className={styles['navbar__li']}>
        <Link to="/">きろくのメモ</Link>
      </a>
        {/* </li> */}
         {/* <li className={styles['navbar__li']}> */}
          {/* <Link to="/about">ABOUT</Link> */}
        {/* </li> */}
      {/* </ul> */}
    </nav>
  );
};

export default NavBar;


// import React, {useState, useEffect } from 'react'
// import { FaBars , FaTimes } from "react-icons/fa"
// import { IconContext } from "react-icons/lib"
// import { 
//   Nav, 
//   NavbarContainer, 
//   NavLogo, 
//   NavIcon, 
//   MobileIcon,
//   NavLinks,
//   NavItem,
//   NavMenu,
// } from "./NavbarElements"

// const Navbar = () => {
//     const [click, setClick] = useState(false)
//     const [scroll, setScroll] = useState(false)
//    /* クリックするとclickの値が反転するようにメソッドを定義 */
//     const handleClick = () => setClick(!click)

//     /*　スクロールが80に達するとscrollがtrueになる　*/
//     const changeNav = () => {
//       if (window.scroll >=80){
//         setScroll(true)
//       }else {
//         setScroll(false)
//       }
//     }

//     /* スクロールイベントがあるごとにを実行させる　*/
//     useEffect(() => {
//       changeNav()
//       window.addEventListener("scroll", changeNav)
//     }, [])

//   return(
//     <>
//       {/* <IconContext.Provider value={{ color: "#141414"}}> */}
//         <Nav active = {scroll} click = {click}>
//           <NavbarContainer>
//             {/* <NavLogo to='/'> */}
//               {/* <NavIcon /> */}
//               <NavLinks to='/'>きろくのメモ</NavLinks>
//             {/* </NavLogo> */}
//             {/* <NavMenu onClick={handleClick} click={click}>
//               <NavItem>
//                 <NavLinks to='/'>Home</NavLinks>
//               </NavItem>
//               <NavItem>
//                 <NavLinks to='/images'>Images</NavLinks>
//               </NavItem>
//               <NavItem>
//                 <NavLinks to='/destinations'>Destinations</NavLinks>
//               </NavItem>
//             </NavMenu> */}
//           </NavbarContainer>
//         </Nav>
//       {/* </IconContext.Provider> */}
//     </>
//   )
// }

// export default Navbar;